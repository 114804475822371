<template>
	<div class="contact">
		<section class="section bg-gray">
			<div class="container tem">
				<h3 class="section-title">Contact Us</h3>
				<p>At BitFree Capital, we pride ourselves on our customer service. Feel free to reach out should you have any questions, comments, concerns, or even if you’d just like to learn. We are always happy to help you to the best of our ability. The easiest ways to reach us are by the form below, website chat (during office hours), phone, or by email. </p>
				<p>Whether offline or online, we are growing a community that encourages and inspires people. We spark discussions, educate, and engage people in the global Bitcoin and Crypto community in a way that resonates. Additionally if you’re a Bitcoin veteran or new to the space, we welcome you and hope you’ll engage with us and our community. </p>
				<div class="form-wraper">
					<div class="former">
						<div class="suc" v-if="suc">
							<div class="tc mb20">
								<img src="/imgs/icons8-ok-48.png">
							</div>
							<div class="tc oks">
								Submit Success!
							</div>
						</div>
						<div v-else>
							<div class="form-row">
								<div class="form-title">
									<span>Your Name</span>
									<span>*</span>
								</div>
								<div>
									<input type="text" class="form-input" v-model="form.first_name">
								</div>
							</div>
							<div class="form-row">
								<div class="form-title">
									<span>Email</span>
									<span>*</span>
								</div>
								<div>
									<input type="text" class="form-input" v-model="form.email">
								</div>
							</div>
							<div class="form-row">
								<div class="form-title">
									Phone
								</div>
								<div>
									<input type="text" class="form-input" v-model="form.phone">
								</div>
							</div>
							<div class="form-row">
								<div class="form-title">
									Quetion or Message
								</div>
								<div>
									<textarea rows="6" class="msg-input" v-model="form.message"></textarea>
								</div>
							</div>
							<div class="form-row">
								<div class="req-err" v-if="err">{{err}}</div>
								<button class="send-btn" @click="create" :disabled="loading">
									<div class="loader loader3" v-if="loading"></div>
									<span v-else>Send</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	import axios from 'axios'
	export default{
		name:'Contact',
		data(){
			return {
				loading: false,
				err:'',
				suc:false,
				form:{
					first_name:'',
					phone:'',
					email:'',
					message:''
				}
			}
		},
		methods:{
			create(){
				if (!this.form.first_name) {
					this.err = 'Please enter your name'
					return
				}
				if (!this.form.email) {
					this.err = 'Please enter email'
					return
				}
				if (!this.form.phone) {
					this.err = 'Please enter phone number'
					return
				}
				this.loading = true
				axios.post('http://bitfree.digital/api/open/account', this.form).then(res=>{
					this.loading = false
					this.suc = true
				})
			},
		}
	}
</script>
<style lang="scss" scoped>
	.contact{
		padding-top: 64px;
	}
	.tem{
		h1{
			font-size: 26px;
			margin-bottom: 20px;
		}
		p{
			font-size: 14px;
			line-height: 32px;
		}
	}
	.form-wraper{
		margin-top: 50px;
	}
	.former{
		width: 420px;
		margin: 0 auto;
	}
	.form-row{
		margin-bottom: 20px;
	}
	.form-title{
		font-size: 15px;
		margin-bottom: 5px;
	}
	.form-input{
		width: 340px;
		height: 34px;
		border: solid 1px #d1d1d1;
		outline: none;
		padding-left: 8px;
		border-radius: 4px;
	}
	.form-input:focus{
		border-color: #FCD535;
	}
	.msg-input{
		width: 340px;
		resize: none;
		border: solid 1px #d1d1d1;
		outline: none;
		border-radius: 4px;
		padding-left: 8px;
		padding-top: 8px;
	}
	.send-btn{
		padding: 10px 16px;
		background-color: #f7bd05;
		color: #212833;
		font-size: 15px;
		font-weight: 600;
		border: none;
		min-width: 180px;
		border-radius: 4px;
		cursor: pointer;
		transition: all ease-in .3s;
	}
	.send-btn:hover{
		background-color: #d19f02;
	}
	.req-err{
		color: #f00;
		margin-bottom: 5px;
	}
	.suc{
		padding: 50px 0;
	}
	.oks{
		font-size: 16px;
	}
</style>